.Jumbo {
    padding: 200px 0;
    text-align: center;
}
.Jumbo-heading {
    color: white;
    font-size: 300%;
    font-weight: bold;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.Jumbo-message {
    color: white;
    font-size: large;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.Jumbo-button.btn.btn-primary {
    background-color: #437b50;
    border-color: #437b50;
    font-size: large;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: bold;
}
.Jumbo-button.btn.btn-primary:hover {
    background-color: #013220;
    border-color: #013220;
    font-size: large;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: bold;
}