.ProductsJumbo-bg-opacity{
    position: relative;
    background-color: #000;
  }
  
.ProductsJumbo-bg-opacity::before{
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    opacity: 0.3;
    background: url("./product-jumbo.jpg") no-repeat center center;
    background-size: cover;
}

.ProductsJumbo-content{
    position: relative;
    width: 100%;
    height: 600px;
    top: 200px;
}

.OurProducts-headings {
    position: relative;
    font-weight: bold;
    color: #414040;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    padding-top: 3%;
    text-align: left;
    padding-bottom: 3%;
}

.OurProducts-packaging-table {
    text-align: left;
}

.OurProducts-packaging-image{
    background-image: url('./packaging-pictures/packaging-sizes.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height: 200px;
    text-align: center;
}