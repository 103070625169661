.NavigationBar-link {
    color: gray;
    font-weight: bold;
}
.NavigationBar-link:hover {
    color: #437b50;
    text-decoration: none;
}
.NavigationBar-logo {
    width: 150px;
    height: auto;
}