.Blog-bg-opacity{
    position: relative;
    background-color: #000;
  }
  
.Blog-bg-opacity::before{
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    opacity: 0.3;
    background: url("./podcast.jpg") no-repeat center center;
    background-size: cover;
}

.Blog-content{
    position: relative;
    width: 100%;
    height: 600px;
    top: 200px;
}

.Blog-heading {
    margin: auto;
    position: relative;
    font-weight: bold;
    color: #414040;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    padding-top: 3%;
    text-align: left;
    padding-bottom: 3%;
}

.Blog-message {
    text-align: left;
    font-size: medium;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    line-height: 1.8;
    font-weight: 400;
  }