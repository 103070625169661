.footer-bg-opacity{
    position: relative;
    background-color: #000;
    color: white;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    height: 100%;
}

.footer-bg-opacity::before{
  content: ' ';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  opacity: 0.4;
  background: url("./footer-microgreen.jpg") no-repeat center center;
  background-size: cover;
}

.footer-content{
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
}

.footer-logo-column {
  color: white;
  font-size: x-large;
  text-align: center;
}
.footer-logo-email {
  color: white;
  font-size: x-large;
  text-align: center;
}

.footer-social {
  color: white;
  font-size: xx-large;
  text-align: center;
}

.footer-social:hover {
  color: #437b50;
}

.footer-social-email {
  color: white;
  text-indent: 10%;
}

.FootPage-Link {
  color: white;
  font-weight: bold;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.FootPage-Link:hover {
  color: #437b50;
  text-decoration: none;
}